import React, { useEffect, useState } from 'react';
import './hotels.css';
import { useTranslation } from 'react-i18next'

function Hotels() {

  const [imageSrc, setImageSrc] = useState('/images/Be dikey.jpg');
  useEffect(() => {
    const handleSrc = () => {
      if (window.innerWidth >= 1024) {
        setImageSrc('/images/Be.jpg'); // Change image size for medium breakpoint
      } else {
        setImageSrc('/images/Be dikey.jpg'); // Default image size
      }
    };
    handleSrc();
    window.addEventListener('resize', handleSrc);
    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleSrc);
    };
  }, []);

  const { t } = useTranslation();

  return (
    <div id='hotels'>
      <section>
        <div className="sm:px-6 sm:py-12 lg:px-8 m-10">
          <h2 className="glorious-font text-[#BFA36B] text-5xl ml-3">{t("hotels")}</h2>
          <ul className="mt-2 grid lg:grid-cols-3">
            <li className='relative p-4'>
              <a href={t("book-url-bodrum")} target="_blank" rel="noopener noreferrer"
                className='button flex justify-center absolute text-white m-6 w-[150px] z-10 end-4 px-5 py-1'
              >{t("hotels-book")}
              </a>
              <a href={t("url-bodrum")} target="_blank" rel="noopener noreferrer" className="group block overflow-hidden">
                <img
                  src="/images/bodrum.jpg"
                  alt=""
                  className="h-full w-full object-cover transition duration-500 group-hover:scale-105"
                />
              </a>
              <h3 className='hotel-names-font absolute z-10 bottom-[70px] left-[30px] text-white text-2xl font-bold'>Duja<br />
                Bodrum <br /> </h3>
              <h3 className=' hotel-visit-font absolute z-10 bottom-[30px] left-[30px] text-white text-2xl font-bold'> <hr /> {t("visit-website")}</h3>
            </li>

            <li className='relative p-4'>
              <a href={t("book-url-didim")} target="_blank" rel="noopener noreferrer"
                className='button flex justify-center absolute text-white m-6 w-[150px] z-10 end-4 px-5 py-1'
              >{t("hotels-book")}
              </a>
              <a href={t("url-didim")} target="_blank" rel="noopener noreferrer" className="group block overflow-hidden">
                <img
                  src="/images/Didim_new.jpg"
                  alt=""
                  className="h-full w-full object-cover transition duration-500 group-hover:scale-105"
                />
              </a>
              <h3 className='hotel-names-font absolute z-10 bottom-[70px] left-[30px] text-white text-2xl font-bold'>Duja<br />
                Didim <br /> </h3>
              <h3 className=' hotel-visit-font absolute z-10 bottom-[30px] left-[30px] text-white text-2xl font-bold'> <hr /> {t("visit-website")}</h3>
            </li>

            <li className='relative p-4'>
              <a href={t("book-url-chalet")} target="_blank" rel="noopener noreferrer"
                className='button flex justify-center absolute text-white m-6 w-[150px] z-10 end-4 px-5 py-1'
              >{t("hotels-book")}
              </a>
              <a href={t("url-chalet")} target="_blank" rel="noopener noreferrer" className="group block overflow-hidden">
                <img
                  src="/images/kars.jpg"
                  alt=""
                  className="h-full w-full object-cover transition duration-500 group-hover:scale-105"
                />
              </a>
              <h3 className='hotel-names-font absolute z-10 bottom-[70px] left-[30px] text-white text-2xl font-bold'>Duja<br />
                Chalet <br /> </h3>
              <h3 className=' hotel-visit-font absolute z-10 bottom-[30px] left-[30px] text-white text-2xl font-bold'> <hr /> {t("visit-website")}</h3>
            </li>
          </ul>
          <ul className="mt-2 grid">
            <li className='relative p-4'>
              <a href={t("book-url-bepremium")} target="_blank" rel="noopener noreferrer"
                className='button flex justify-center absolute text-white m-6 w-[150px] z-10 end-4 px-5 py-1'
              >{t("hotels-book")}
              </a>
              <a href={t("url-bepremium")} target="_blank" rel="noopener noreferrer" className="group block overflow-hidden">
                <img
                  src={imageSrc}
                  alt=""
                  className="h-full w-full object-cover transition duration-500 group-hover:scale-105"
                />
              </a>
              <h3 className='hotel-names-font absolute z-10 bottom-[70px] left-[30px] text-white text-2xl font-bold'>Be Premium<br />
                Bodrum <br /> </h3>
              <h3 className=' hotel-visit-font absolute z-10 bottom-[30px] left-[30px] text-white text-2xl font-bold'> <hr /> {t("visit-website")}</h3>
            </li>
          </ul>
        </div>
      </section>
    </div>
  )
}

export default Hotels
import { I18nextProvider } from 'react-i18next';
import i18n from './i18h'; // Your i18n configuration
import NavBar from "./components/NavBar";
import Entrance from "./components/Entrance";
import Hotels from "./components/Hotels";
import Opportunities from "./components/Opportunities";
import FollowUs from "./components/FollowUs";
import CTA from "./components/CTA";
import Video from "./components/Video";
import Contact from "./components/Contact";
import ScrollToTop from "./components/ScrollToTop";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div>
      <I18nextProvider i18n={i18n}>
        <NavBar />
        <Entrance />
        <CTA />
        <Hotels />
        <Video />
        <FollowUs />
        <Opportunities />
        <Contact />
        <ScrollToTop />
      </I18nextProvider>
    </div>
  );
}

export default App

import { createBrowserRouter, Navigate } from "react-router-dom";

// Home And Main Home1
import App from "../App";

// All InnerPage


// Starting React Router.
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [      
      {
        path: "*",
        element: <Navigate to='/' />,
      },
    ],
  },  
]);

export default router;

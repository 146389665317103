import React from 'react'
import { RiInstagramFill } from "react-icons/ri";
import InstagramImageFetcher from './InstagramImageFetcher';
import { useTranslation } from 'react-i18next'

const FollowUs = () => {
    const accessToken = 'IGQWRQV2lDd2FveU81SGFlZAUV4T2dzX0FIY0ZAXeXFIWGdLOWMta29VdmZA5TjVWNTJocUw1b1pZAbXhkSVNvR3N3Y2t0VDFNeHhJMHRvX2huUzI1R1FsdDlabkRCRjlNa094ZAklPUHhpMjdmbE9qUnduOXM2UldHQVEZD';
    const { t } = useTranslation();
    return (
        <div>
            <div className='w-[90%] mx-[8%] my-[7%] justify-center'>
                <h1 className='text-[#A8A7A7] text-4xl'>{t("follow-us")}</h1> <br />
                <div className='flex items-center gap-1'>
                    <RiInstagramFill className='text-[#BFA36B] size-[3.6em]' />
                    <h3 className='text-[#BFA36B] text-4xl'> duja_hotels </h3>
                </div>
                <br />
                <InstagramImageFetcher accessToken={accessToken} />
            </div>
        </div>
    )
}

export default FollowUs
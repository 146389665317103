import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import i18n from '../i18h';

const LanguageSelector = () => {
    

    const navigate = useNavigate();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (e) => {
        e.preventDefault();
        i18n.changeLanguage(e.target.value);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(e.target.value);
        localStorage.setItem("lang", e.target.value);
       
    }

    return (
        <select className='hotel-visit-font font-bold text-[#193F4A]'
            defaultValue={selectedLanguage} onChange={chooseLanguage}>
            <option value="tr">TR</option>
            <option value="en">EN</option>
            <option value="ru">RU</option>
            <option value="de">DE</option>
        </select>
    )
}

export default LanguageSelector